import { useEffect } from "react";
import { useSelector } from 'react-redux';
import NavigationItemsHelper from "../helpers/NavigationItemsHelper";
import routes from "../constants/routes";


const rootElementId = 'amazon-connect-chat-widget';

const chatWidgetButton = {
    ariaLabels: {
        startChat: 'Start Chat',
        minimizeChat: 'Minimize Chat'
    }
};

const config = {
    styles: { iconType: 'CHAT', openChat: { color: '#ffffff', backgroundColor: '#E66C28' }, closeChat: { color: '#ffffff', backgroundColor: '#E66C28' } },
    snippetId: null,
    supportedMessagingContentTypes: ['text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response'],
    customStyles: {
        global: {
            frameWidth: '50vh',
            frameHeight: '60vh',
            textColor: '#000000',
            footerHeight: '70px',
            typeface: 'Verdana',
            headerHeight: '70px'
        },
        header: {
            headerTextColor: '#ffffff',
            height: '50px'
        },
        transcript: {
            messageFontSize: '16px',
            messageTextColor: '#000000'
        },
        footer: {
            buttonFontSize: '20px',
            buttonTextColor: '#000000',
        }
    }
};


const buildAndAppendScript = ({ id, url }) => {
    const script = document.createElement('script');
    script.id = id;
    script.src = url;
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);

    const defaultAmazonConnectFunction = function () {
        (window['amazon_connect'].ac = window['amazon_connect'].ac || []).push(arguments);
    };

    window['amazon_connect'] = window['amazon_connect'] || defaultAmazonConnectFunction;
};

const setAmazonConnectScriptConfig = configOptions => {
    for (let key in configOptions) {
        if (config.hasOwnProperty(key)) {
            window['amazon_connect'](key, configOptions[key]);
        }
    }
}

const manageChatWidgetButtonVisibility = (navigationItems) => {

    const isActivePageFeedbackPage = NavigationItemsHelper.isActivePage(navigationItems, routes.FEEDBACK.ROUTE);

    const rootElement = document.getElementById(rootElementId);

    const elementSelector = 'button[aria-label="' + chatWidgetButton.ariaLabels.startChat + '"], button[aria-label="' + chatWidgetButton.ariaLabels.minimizeChat + '"]';
    const button = rootElement?.querySelector(elementSelector);

    if (button) {
        button.style.opacity = 1;

        if (button.getAttribute('aria-label') === chatWidgetButton.ariaLabels.startChat && isActivePageFeedbackPage) {
            button.style.opacity = 0;
        };

    }
};


export default function useAmazonConnect({ id, url, snippetId }) {

    const { navItems } = useSelector(state => state.main);

    useEffect(() => {

        if (!id || !url || !snippetId) return;

        buildAndAppendScript({ id, url });

        config.snippetId = snippetId;

        setAmazonConnectScriptConfig(config);

        return () => {
            const script = document.getElementById(id);
            script.remove();
            delete window['amazon_connect'];
        };

    }, []);

    useEffect(() => {
        manageChatWidgetButtonVisibility(navItems);
    }, [navItems]);

}









